<template>
  <div class="content">
    <v-app align="center">
      <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="3000"
      centered
    >
      {{ text }}
    </v-snackbar>
    <v-overlay absolute :value="otpLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
      <div>
        <v-row justify="center">
          <v-dialog v-model="errorModal" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                Something went wrong...
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogErrorModal">
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div class="adminContainer">
        <div class="leftDiv"> 
          <img class="divImage" src="https://s3iconimages.mymedicine.com.mm/doctorLoginBackgroundImage.svg"/>
        </div>
        <div class="loginContainer">
          <div class="loginBox" v-if="showLoginComponent">
            <h1>Sign in </h1>
            <div class="formDiv">
              <div>
                <label for="emailId" style="display: block; text-align: left;">Email<span style="color: red;">*</span></label>
                <v-text-field label="Enter Your Email" 
                  solo 
                  flat = false
                  type="email"
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="email"
                  :rules="emailInputRules"
                ></v-text-field>
              </div>
              <div>
                <label for="passwordId" style="display: block; text-align: left;">Password<span style="color: red;">*</span></label>
                <v-text-field label="Enter Your Password" 
                  solo
                  flat = false
                  :rules="passwordInputRules"
                  :type="show1 ? 'text' : 'password'" 
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </div>
              <div class="rememberMeDiv">
                <v-checkbox label="Remember me"></v-checkbox>
                <span style="cursor: pointer; " @click="showForgotPassword">Forgot Password?</span>
              </div>
              <v-btn
                color="primary"
                :loading="submitLogin"
                variant="elevated"
                block
                class="mt-2"
                @click="signInViaEmailPassword"
                :disabled="isSubmitBtnDisableForEmailPassword"
              >Submit</v-btn>
            </div>
            <div style="margin: 10px 0px;">OR</div>
            <div v-if="!isLoading">
              <div ref="googleLoginBtn" class="googleBtn"/>
            </div>
            <div v-else>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <p>Please wait. Verifying details.</p>
            </div>
          </div>
          <div v-else-if="!showLoginComponent && !forgotPasswordComponent" class="loginBox">
          <div class="spc">
            <div class="formDiv">
              <div class="setNewPswdh1">
                <h1>Set New Password </h1>
                <img style="width: 40px; height: 40px; cursor: pointer;" @click="reload" src="https://s3iconimages.mymedicine.com.mm/back_button.svg"/>
              </div>
              <div>
                <label for="newPasswordId" style="display: block; text-align: left;">Password<span style="color: red;">*</span></label>
                <v-text-field label="Enter Your Password" 
                  solo
                  flat = false
                  :rules="newPasswordInputRules"
                  :type="showNewPassword ? 'text' : 'password'" 
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="newPassword"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNewPassword = !showNewPassword"
                ></v-text-field>
              </div>
              <div>
                <label for="newPasswordConfirmId" style="display: block; text-align: left;">Confirm Password<span style="color: red;">*</span></label>
                <v-text-field label="Re-enter the password" 
                  solo
                  flat = false
                  :rules="confirmPasswordInputRules"
                  :type="showConfirmPassword ? 'text' : 'password'" 
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="newPasswordConfirm"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                ></v-text-field>
              </div>
              <br/>
              <v-btn
                color="primary"
                :loading="submitLogin"
                variant="elevated"
                block
                class="mt-2"
                @click="updatePassword"
                :disabled="setNewPasswordSubmitIsDisabled"
              >Submit</v-btn>
            </div>
          </div>
          </div>
          <div v-else class="loginBox">
            <div class="spc" v-if="!allowChangePassword">
              <div class="formDiv">
                <div class="setNewPswdh1">
                  <h1>Forgot Password? </h1>
                  <img style="width: 40px; height: 40px; cursor: pointer;" @click="reload" src="https://s3iconimages.mymedicine.com.mm/back_button.svg"/>
                </div>
                <div>
                  <label for="newPasswordId" style="display: block; text-align: left;">Email<span style="color: red;">*</span></label>
                  <v-text-field label="Enter Your email" 
                    solo
                    flat = false
                    :rules="emailInputRules"
                    background-color="rgba(244, 246, 250, 1)"
                    v-model="verifyEmailInput"
                    :readonly="isReadOnly"
                  ></v-text-field>
                </div>
                <div v-if="showOTPBox">
                  <label for="newPasswordId" style="display: block; text-align: left;">Enter OTP<span style="color: red;">*</span></label>
                    <v-otp-input
                      v-model="otp"
                      load
                      :disabled="otpLoading"
                      @finish="onFinish"
                      length="6"
                    ></v-otp-input>
                </div>
                <br/>
                <v-btn
                  color="primary"
                  :loading="submitLogin"
                  variant="elevated"
                  block
                  class="mt-2"
                  @click="getOTP"
                  :disabled="sendOtpFogotPasswordSubmitIsDisabled"
                >Get otp</v-btn>
                <p v-if="timerValue !== 0">You can resend the otp after {{this.timerValue}}s</p>
              </div>
            </div>
            <div class="spc" v-else>
              <div class="formDiv">
                <div class="setNewPswdh1">
                  <h1>Set New Password </h1>
                  <img style="width: 40px; height: 40px; cursor: pointer;" @click="reload" src="https://s3iconimages.mymedicine.com.mm/back_button.svg"/>
                </div>
                <div>
                  <label for="newPasswordId" style="display: block; text-align: left;">Password<span style="color: red;">*</span></label>
                  <v-text-field label="Enter Your Password" 
                    solo
                    flat = false
                    :rules="newPasswordInputRules"
                    :type="showNewPassword ? 'text' : 'password'" 
                    background-color="rgba(244, 246, 250, 1)"
                    v-model="newPassword"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showNewPassword = !showNewPassword"
                  ></v-text-field>
                </div>
                <div>
                  <label for="newPasswordConfirmId" style="display: block; text-align: left;">Confirm Password<span style="color: red;">*</span></label>
                  <v-text-field label="Re-enter the password" 
                    solo
                    flat = false
                    :rules="newPasswordInputRules"
                    :type="showConfirmPassword ? 'text' : 'password'" 
                    background-color="rgba(244, 246, 250, 1)"
                    v-model="newPasswordConfirm"
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                  ></v-text-field>
                </div>
                <br/>
                <v-btn
                  color="primary"
                  :loading="submitLogin"
                  variant="elevated"
                  block
                  class="mt-2"
                  @click="forgotPassword"
                  :disabled="setNewPasswordSubmitIsDisabled"
                >Submit</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import jwt_decode from "jwt-decode";
import { base_axios_instance } from '../../../utils/axios_utils';


export default {
  name: "LoginPage",

  data() {
    return {
      isReadOnly: false,
      waitForResendOTP: false,
      text: 'text',
      snackbar: false,
      snackbarColor: 'default',
      allowChangePassword: false,
      mode: '',
      gauthToken: '',
      showOTPBox: false,
      forgotPasswordComponent: false,
      forgotPasswordOTPToken: true,
      otpLoading: false,
      timerValue: 0,
      otp: '',
      verifyEmailInput: '',
      currentUser: "",
      errorModal: false,
      isLoading: false,
      isDisabled: true,
      disableTimer: false,
      showLoginComponent: true,
      newPassword: '',
      newPasswordConfirm: '',
      email: '',
      password: '',
      submitLogin: false,
      show1: false,
      showNewPassword: false,
      showConfirmPassword: false,
      allErrors: ['Invalid e-mail.','Min 8 characters', 'Required.' ],
      emailInputRules: [(value) => !!value || 'Required.', (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const allowedEmail = ["kbzbank.com", "kbxdigital.com", "kbxholdings.com", "mymedicine.com.mm", "gmail.com", "codestax.ai"].indexOf(value.split("@")[1]);
            return pattern.test(value) && allowedEmail >= 0  || 'Invalid e-mail.'
          }],
      passwordInputRules: [(value) => !!value || 'Required.'],
      newPasswordInputRules: [(value) => !!value || 'Required.', (v) => v.length >= 8 || 'Min 8 characters'],
      confirmPasswordInputRules: [(value) => !!value || 'Required.', (v) => v.length >= 8 || 'Min 8 characters', (v) => v === this.newPassword || 'both password must be same']

    };
  },
  computed: {
    isSubmitBtnDisableForEmailPassword: function() {
      return (this.emailInputRules.some((rule) => this.allErrors.includes(rule(this.email))) || this.passwordInputRules.some((rule) => this.allErrors.includes(rule(this.password))));
    },
    setNewPasswordSubmitIsDisabled: function() {
      return (this.newPasswordInputRules.some((rule) => this.allErrors.includes(rule(this.newPassword))) || this.confirmPasswordInputRules.some((rule) => this.allErrors.includes(rule(this.newPasswordConfirm))) || this.newPassword !== this.newPasswordConfirm);
    },
    sendOtpFogotPasswordSubmitIsDisabled: function() {
      return (this.emailInputRules.some((rule) => this.allErrors.includes(rule(this.verifyEmailInput))) || this.waitForResendOTP);
    }
  },
  methods: {
    reload() {
      this.$router.go()
    },
    async getOTP() {
      try {
        this.otpLoading = true;
        this.timerValue = 60;
        let sendOTPForgotPasswordResponse = await base_axios_instance.post('sendOTPForgotPassword', {email: this.verifyEmailInput, adminType: 'DOCTOR'});
        this.snackbar = true;
        this.otpLoading = false;
        this.isReadOnly = true;
        this.text = `OTP SENT ON YOUR ${this.verifyEmailInput}`;  
        this.waitForResendOTP = true;
        if (sendOTPForgotPasswordResponse.data.firstLogin) {
          this.showOTPBox = false;
          this.snackbar = true;
          this.text = 'EMAIL AND PASSWORD SENT ON YOUR MAIL.'
          alert('Email and Password Sent on mail')
          this.$router.go();
        } else {
          this.forgotPasswordOTPToken = sendOTPForgotPasswordResponse.data.token;
          this.showOTPBox = true;
          this.waitForResendOTP = true;
          let stp = setInterval(() => {
              this.timerValue -= 1;
              if (this.timerValue === 0) {
                this.waitForResendOTP = false;
                clearInterval(stp);
              }
          }, 1000)
        }
      } catch (error) {
          this.otpLoading = false;
          if (error.response) {
            this.snackbar = true;
            console.log(error.response.data.message);
            this.text = error.response.data.message;
          } else if (error.request) {
            this.snackbar = true;
            this.text = 'Network error';
          } else {
            this.snackbar = true;
            this.text = 'Somthing went wrong';
          }
      }
      this.showOTPBox = true; 
    },
    async forgotPassword() {
        this.otpLoading = true;
        try {
          let reqBody = {};
          reqBody = {
              email: this.verifyEmailInput,
              password: this.CryptoJS.AES.encrypt(this.newPassword, process.env.VUE_APP_ENCRYPTION_KEY).toString(),
              adminType: 'DOCTOR',
              otp: this.otp,
              token: this.forgotPasswordOTPToken
            };
          let res = await base_axios_instance.post('/forgotPasswordDoctor', reqBody);
          this.snackbar = true;
          this.text = "Reset password successfull"
          this.otpLoading = false;
          this.$router.go();
        } catch(error) {
          this.otpLoading = false;
          if (error.response) {
            this.snackbar = true;
            console.log(error.response.data.message);
            this.text = error.response.data.message;
          } else if (error.request) {
            this.snackbar = true;
            this.text = 'Network error';
          } else {
            this.snackbar = true;
            this.text = 'Somthing went wrong';
          }
        } finally {
          this.otpLoading = false;
          this.submitLogin = false;
        }
      },
    async onFinish (rsp) {
      try {
        this.otpLoading = true;
        await base_axios_instance.post('verifyOTPForgotPassword', { token: this.forgotPasswordOTPToken, adminType: 'DOCTOR', otp: this.otp, email: this.verifyEmailInput});
        this.allowChangePassword = true;
        this.otpLoading = false;
      } catch (error) {
          this.otpLoading = false;
          if (error.response) {
            this.snackbar = true;
            console.log(error.response.data.message);
            this.text = error.response.data.message;
          } else if (error.request) {
            this.snackbar = true;
            this.text = 'Network error';
          } else {
            this.snackbar = true;
            this.text = 'Somthing went wrong';
          }
      }
    },
    showForgotPassword() {
      this.showLoginComponent = false;
      this.forgotPasswordComponent = true;
    },
    renderSignInButton() {
      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_CLIENT_ID,
        callback: this.handleCredentialResponse,
        prompt: 'select_by',
        scope: "email"
      })
      window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
          text: 'Login',
          size: 'large',
          width: '300px',
          theme: 'filled_blue',
        }
      )
    },
    closeDialogErrorModal(){
         this.errorModal = false;
         this.$router.go();
    },
    async handleCredentialResponse(signInSuccessResponse) { 
      try {
        this.submitLogin = true;
        this.isLoading = true;
        const accessToken = signInSuccessResponse.credential;
        const signedInuserDetails = await jwt_decode(accessToken);
        var email = signedInuserDetails.email;
        if (["kbzbank.com", "kbxdigital.com", "kbxholdings.com", "mymedicine.com.mm", "gmail.com", , "codestax.ai"].indexOf(email.split("@")[1]) >= 0){
                const encryptedText = this.CryptoJS.AES.encrypt(accessToken + " " + email + " " + "DOCTOR", process.env.VUE_APP_ENCRYPTION_KEY).toString()
                var createDoctorTokenData = {
                  key : encryptedText
                }
                axios.post(process.env.VUE_APP_BACKEND_URL + "/createAdminToken", createDoctorTokenData)
                .then((CreateDoctorTokenResponse) => {
                  if (CreateDoctorTokenResponse.data.firstLogin) {
                    this.submitLogin = false;
                    this.showLoginComponent = false;
                    this.email = email;
                    this.gauthToken = encryptedText;
                    this.mode = 'GAUTH';
                    return ;
                  }
                    var getUserAccessData = {
                        token : CreateDoctorTokenResponse.data.data,
                        typeOfUser : "DOCTOR",
                        adminType : "DOCTOR"
                    }
                    axios
                    .post(process.env.VUE_APP_BACKEND_URL+"/getAdmin", getUserAccessData)
                    .then((response) =>{
                        this.submitLogin = false;
                    if(response.data.message == "SUCCESS")
                    {
                        this.$cookies.set("doctorToken",CreateDoctorTokenResponse.data.data, "1w");
                        this.$router.push({
                        name: "DoctorHome",
                        });
                    }
                    else {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$router.go();
                    }
                })
                .catch((error) =>{
                  this.submitLogin = false;
                  this.isLoading = false;
                  this.$router.go();
                });
          }).catch((CreateDoctorTokenError) => {
                    this.isLoading = false;
                    this.submitLogin = false;
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$router.go();
                });
        }
          else {
            this.submitLogin = false;
            this.isLoading = false;
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.go();
          }
      } catch (err) {
                this.submitLogin = false;
                console.log(err);
                this.isLoading = false;
                window.alert("Something went wrong");
                this.$router.go();
      }
    },
    async signInViaEmailPassword() {
      if (this.isSubmitBtnDisableForEmailPassword) return;
      this.submitLogin = true;
          let encryptedPassword = this.CryptoJS.AES.encrypt(this.password, process.env.VUE_APP_ENCRYPTION_KEY).toString()
      try {
       let response = await base_axios_instance.post('/passwordLogin', { adminType: 'DOCTOR', email: this.email, password: encryptedPassword });
       if (response.data.firstLogin) {
            this.submitLogin = false;
            this.showLoginComponent = false;
            this.mode = 'EMAIL';
            return;
       }
       let token = response.data.token;
       response = await base_axios_instance.post('/getAdmin', { token : response.data.token, adminType : "DOCTOR", typeOfUser : "DOCTOR" });
       if(response.data.message == "SUCCESS"){
          this.submitLogin = false;
          this.$cookies.set("doctorToken",token, "1w");
          this.$router.push({
            name: "DoctorHome",
          });
        } else {
            this.submitLogin = false;
            this.isLoading = false;
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.go();
        } 
      } catch (error) {
          this.submitLogin = false;
          if(error.response.status == 401){
              this.isLoading = false;
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.go();
          }
      }
    },
    async updatePassword() {
      this.submitLogin = true;
      try {
        let reqBody = {};
        if (this.mode === 'GAUTH') {
          reqBody = {
            password: this.CryptoJS.AES.encrypt(this.newPassword, process.env.VUE_APP_ENCRYPTION_KEY).toString(),
            token: this.gauthToken,
            mode: 'GAUTH',
            adminType: 'DOCTOR'
          }
        } else {
          reqBody = {
            email: this.email,
            oldPassword: this.CryptoJS.AES.encrypt(this.password, process.env.VUE_APP_ENCRYPTION_KEY).toString(),
            password: this.CryptoJS.AES.encrypt(this.newPassword, process.env.VUE_APP_ENCRYPTION_KEY).toString(),
            mode: 'EMAIL',
            adminType: 'DOCTOR'
          }
        }
        let encryptedText = this.CryptoJS.AES.encrypt(JSON.stringify(reqBody), process.env.VUE_APP_ENCRYPTION_KEY).toString()
        let res = await base_axios_instance.post('/updatePassword', {key: encryptedText});
        alert('Password changed successfully');
        this.$router.go();
      } catch(error) {
        console.log(error);
        alert('error');
        this.$router.go();
      } finally {
        this.submitLogin = false;
      }
    },
  },
  mounted(){
    this.currentUser = this.$cookies.get("doctorToken");
    document.title = "Doctor Login"
    if(this.currentUser)
    {
      this.isLoading = true;
      var getAccessData = {
        token : this.currentUser,
        typeOfUser : "DOCTOR",
        adminType : "DOCTOR"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAccessData)
      .then((getAdminResponse) => {
        this.$router.push({
          name: "DoctorHome"
        });
      })
      .catch((getAdminError) => {
        this.$cookies.remove("doctorToken");
        this.errorModal = true;
        this.isLoading = false;
        this.renderSignInButton();
      });
    } else {
        this.renderSignInButton();
    }
  }
};
</script>

<style scoped>

label {
  padding-bottom: 4px;
}
.rememberMeDiv {
  height: 10px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rememberMeDiv span {
  color: rgba(130, 130, 130, 1);
}

.adminContainer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  background: rgba(244, 246, 250, 1);
  max-height: 100vh;
  border: none;
}
.divImage {
  height: 100%;
}
.loginContainer {
  box-sizing: border-box;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formDiv {
  width: 65%;
}
.spc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  width:100%;
}
.loginBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://s3iconimages.mymedicine.com.mm/adminBackgroundImage.svg");
  background-size: cover;
  width:85%;
  min-height: 80%;
  padding-top: 90px;
  padding-bottom: 40px;
}
.content {
  width: 100%;
}
 .setNewPswdh1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .adminContainer {
    display: flex;
    flex-direction: column;
    background:white;
  }
  .adminContainer img {
    width: 100%;
    height: 80%;
    object-fit:cover;
  }
  .loginContainer {
    width: 100%;
    position: relative;
    bottom: 20%;
  }
  h1{
    display: block;
    text-align: left;
  }
  .formDiv {
    width: 100%;
  }  
  .loginBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: none;
    background: white;
    background-size: cover;
    width:100%;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 40px 40px 0px 0px;
}
 .setNewPswdh1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}
}
@media only screen and (max-width: 700px) and (max-height: 700px) {
  .adminContainer img {
    width: 100%;
    height: 70%;
    object-fit:cover;
  }
  .loginContainer {
    width: 100%;
    position: relative;
    bottom: 40%;
  }
    .loginBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: none;
    background: white;
    background-size: cover;
    width:100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 40px 40px 0px 0px;
}
}
</style>